export const useTypography: (className: any) => any = (className: {
  dark: boolean,
  error: boolean,
  light: boolean,
  lowDark: boolean,
  mediumDark: boolean,
  primary: boolean
}) => {
  const textClass: string = "text-style";
  const getTextClassName: () => string = () => {
    if (className.light) {
        return `${textClass}--light`;
      } else if (className.primary) {
        return `${textClass}--primary`;
      } else if (className.dark) {
        return `${textClass}--light-level-3`;
      } else if (className.mediumDark) {
        return `${textClass}--light-level-2`;
      } else if (className.lowDark) {
        return `${textClass}--light-level-1`;
      } else if (className.error) {
        return `${textClass}--error`;
      } else {
        return `${textClass}--dark-level-0`;
      }
    return `${className}`;
  }

  return {
    textClass,
    getTextClassName
  }
}